:root {
    /* Z-index */
    --z-index-1: 9;
    --z-index-2: 99;
    --z-index-3: 999;
    --z-index-4: -1;
    --z-index-5: 0;

    /* Textos */
    --font-size-large: 48px;
    --font-size-medium: 24px;
    --font-size-regular: 20px;
    --font-size-small: 18px;
    --font-size-tiny: 16px;

    --font-weight-light: 200;
    --font-weight-regular: 400;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 900;

    /* Colors */
    --bg-primary: #8377D1;
    --bg-secondary: #743A98;
    --bg-secondary-dark: #562178;
    --black: #342140;
    --white: #fff;
}