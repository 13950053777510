body {
  width: 100vw;
  margin: 0px;
  font-family: 'Poppins', sans-serif;
  background-color: var(--white);
  color: var(--black);
  overflow-x: hidden;
  overflow-y: scroll;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--bg-secondary);
  border-radius: 3px;
}

.sectionFake {
  position: absolute;
  bottom: 35vh;
}

#sectionVideo {
  position: absolute;
  top: -5vh;
}

#sectionBenefits {
  position: absolute;
  top: -15vh;
}

#sectionConocenos {
  position: absolute;
  top: -8vh;
}

#sectionContact {
  position: absolute;
  top: -25vh;
}

#sectionBlog {
  position: absolute;
  top: 0;
}

/* ------------- Header ------------- */

header {
  position: fixed;
  top: 0;
  left: 12.5vw;
  width: 75vw;
  height: 120px;
  z-index: calc(var(--z-index-3) + 1);
  transition: box-shadow 0.3s ease;
  background-color: var(--white);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 20px;
  border-radius: 0 0 10px 10px;
  transform-origin: top;
  transition: .3s;
}

header.scrolled {
  transition: .3s;
  background-color: var(--white);
  height: 100px;
  box-shadow: rgba(116, 58, 152, 0.2) 0px -3px 0px inset;
}

nav {
  width: 70%;
  height: auto;
}

header svg {
  transition: .3s;
  transform: scale(0.9);
}

header ul {
  width: 100%;
  height: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header li {
  margin-right: 2%;
  width: auto;
}

.btnNavLi {
  display: flex;
  align-items: center;
  gap: 10px;
}

header li button, header li button a {
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: var(--black);
  font-size: var(--font-size-tiny);
  line-height: var(--font-size-tiny);
  font-weight: var(--font-weight-semi-bold);
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}



header button:hover a {
  color: var(--bg-primary);
  transition: .3s;
}

.line {
  width: 0px;
  height: 2px;
  border-radius: 2px;
  transition: .3s;
  background-color: var(--bg-primary);
  transform-origin: center;
}

.li_icon {
  width: 30px;
  fill: var(--bg-secondary);
  display: none;
}

header li:hover .li_icon {
  fill: var(--bg-primary);
}

header li:hover .line {
  width: 100%;
  animation: lineNav .3s forwards;
}

@keyframes lineNav {
  0% {
      background-color: black;
  }

  100% {
      background-color: var(--bg-primary);
  }
}

button.btnMenuMobileUp {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  gap: 0.65rem;
  transform: scale(0.9);
}

button.btnMenuMobileUp>div {
  background-color: var(--bg-secondary);
  height: 2px;
  width: 100%;
  border-radius: 5px;
  transition: all 0.5s;
  transform-origin: left;
}

button.btnMenuMobileUp:hover div:first-child {
  transform: rotate(45deg);
}

button.btnMenuMobileUp:hover div:nth-child(2) {
  opacity: 0;
}

button.btnMenuMobileUp:hover div:last-child {
  transform: rotate(-45deg);
}

button.btnMenuMobileDown {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  gap: 0.65rem;
  transform: scale(0.9);
}

button.btnMenuMobileDown>div {
  background-color: var(--bg-secondary);
  height: 2px;
  width: 100%;
  border-radius: 5px;
  transition: all 0.5s;
  transform-origin: left;
}

button.btnMenuMobileDown div:first-child {
  transform: rotate(45deg);
}

button.btnMenuMobileDown div:nth-child(2) {
  opacity: 0;
}

button.btnMenuMobileDown div:last-child {
  transform: rotate(-45deg);
}

button.btnMenuMobileDown:hover div:first-child {
  transform: rotate(0deg);
}

button.btnMenuMobileDown:hover div:nth-child(2) {
  opacity: 1;
}

button.btnMenuMobileDown:hover div:last-child {
  transform: rotate(0deg);
}

li.lenguage {
  position: relative;
  width: 60px;
}

.lenguageBtn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 3px;
  width: 60px;
  transition: 0.3s;
  color: var(--black);
  font-size: var(--font-size-tiny);
  line-height: var(--font-size-tiny);
  font-weight: var(--font-weight-semi-bold);
}

#btnNewLenguage {
  display: none;
  position: absolute;
  width: 100%;
  transition: 0.3s;
  transform-origin: top;
  transform: scale(0);
  padding-top: 5px;
  bottom: -100%;
}

.lenguageNewImg {
  transition: 0.3s;
  width: 18px;
}

.lenguageBtn:hover {
  color: var(--bg-primary);
}

.containerMobileNav {
  display: none;
}

/* ------------- Globals ------------- */
.btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

p {
  padding: 0;
  margin: 0;
}

section {
  width: 100vw;
  padding: 0px 12.5vw;
  box-sizing: border-box;
  position: relative;
}

h2 {
  margin: 0px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

/* ------------- Sections ------------- */

/* Home */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  position: relative;
  padding-top: 100px;
  overflow: hidden;
}

.home,
.brands,
.home__mainImage-pace {
  transform-origin: center;
  animation: 1s homeGroup1 ease-in;
}

@keyframes homeGroup1 {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.home .home__text {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-large);
  max-width: 680px;
}

.home .home__text .home__text_main {
  background-color: var(--bg-secondary);
  color: var(--white);
  padding: 0 10px;
}

.home__subimages {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 70px;
}

.home__subimages span {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-large);
  color: var(--bg-secondary);
}

.home__subimages-image {
  width: auto;
  height: 47px;
}

.home__mainImage-pace {
  z-index: var(--z-index-4);
  max-width: 650px;
  width: 65vh;
  max-height: 650px;
  height: 65vh;
  position: absolute;
  right: 12.5vw;
  background-color: var(--bg-secondary);
  overflow: hidden;
  animation: animationMainHome 5s ease-in-out infinite;
  border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  display: flex;
  justify-content: center;
}

.home__mainImage-img {
  position: absolute;
  right: 12.5vw;
  z-index: -1;
  height: 70vh;
}

#home__svg1 {
  position: absolute;
  right: calc(12.5vw + 820px);
  bottom: 120px;
  z-index: 1;
  margin-bottom: 20px;
}

#home__svg1 path, #home__svg2 path, #home__svg3 path, #home__svg4 path {
  fill: #743A98 !important;
}

#home__svg2 {
  position: absolute;
  right: calc(12.5vw);
  top: 120px;
  z-index: 1;
}

#home__svg3 {
  position: absolute;
  right: calc(12.5vw + 280px);
  bottom: 40px;
  z-index: 1;
}

#home__svg4 {
  position: absolute;
  right: calc(12.5vw + 700px);
  top: 220px;
  z-index: 1;
}

@keyframes animationMainHome {
  0% {
      background-color: var(--bg-secondary);
  }

  50% {
      background-color: var(--bg-primary);
  }

  100% {
      background-color: var(--bg-secondary);
  }
}

.btnWsp {
  position: fixed;
  bottom: 15vh;
  right: 12vw;
  z-index: var( --z-index-3);
  background-color: var(--white);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
  align-items: center;
  border-radius: 50%;
  transition: .3s;
  box-shadow: rgba(68, 68, 68, 0.46) 0px 0px 30px 4px;
  transform: scale(1);
}

.btnWsp:hover {
  box-shadow: rgba(47, 47, 47, 0.56) 0px 0px 30px 4px;
  transform: scale(1.05);
  transition: .3s;
}

/* Brands */
.brands {
  display: flex;
  flex-direction: column;
  place-content: center;
  height: 30vh;
}

.brands .brands__text {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.brands__carrousel {
  width: 100%;
  height: 200px;
  display: flex;
}

.brands__carrousel-item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brands__carrousel-img {
  width: auto;
  max-width: 80%;
  height: auto;
  max-height: 150px;
}

.swiper-container-brands {
  width: 100%;
  height: 150px;
}

.swiper-slide-brands {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brands-mobile {
  display: none;
}

.partners {
  margin: 50px 0 200px;
}

/* MediaPlayer */
.mediaplayer {
  width: 100vw;
  height: 100vh;
  max-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: rgba(205, 168, 228, 0.231);  
  margin-top: 100px;
}

.mediaplayer__title {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);
  text-align: center;
}

.mediaplayer__video {
  height: 50vh;
  min-height: 300px;
  aspect-ratio: 16/9;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.mediaplayer__btn {
  width: 200px;
  height: 50px;
  color: var(--white);
  font-size: var(--font-size-small);
  border-radius: 25px;
  padding: 15px 35px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  background: var(--bg-secondary);
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.mediaplayer__btn:hover {
  transform: scale(1.1);
}

.mediaplayer__btn:before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: var(--white);
  animation: btnAmination 3s ease-in-out infinite;
}

@keyframes btnAmination {
  0% {
      -webkit-transform: scale(0) rotate(45deg);
      opacity: 0;
  }

  80% {
      -webkit-transform: scale(0) rotate(45deg);
      opacity: 0.5;
  }

  81% {
      -webkit-transform: scale(4) rotate(45deg);
      opacity: 1;
  }

  100% {
      -webkit-transform: scale(50) rotate(45deg);
      opacity: 0;
  }
}

/* ------------- Bebeficios ------------- */
.benefits {
  background-color: var(--bg-secondary);
  color: var(--white);
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  position: relative;
  overflow: hidden;
}

.benefits-items-mobile {
  display: none;
}

.benefits-items {
  width: 100%;
  display: flex;
  gap: 50px;
  z-index: var(--z-index-1);
}
.benefits-item {
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.benefit__title-paralax {
  position: absolute;
  font-size: 20px;
  z-index: var(--z-index-5);
  background: rgba(79, 13, 119, 0);
background: linear-gradient(0deg, var(--bg-secondary) 0%, var(--bg-secondary) 25%, var(--bg-secondary-dark) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  opacity: 0.8;
}

.benefits-item-title {
  font-size: 60px;
  line-height: 60px;
  font-weight: var(--font-weight-semi-bold);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
}

.benefits-item-title span {
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
}

.benefits-item-text {
  font-weight: var(--font-weight-light);
  text-align: center;
  width: 100%;
  font-size: var(--font-size-small);
  line-height: calc(var(--font-size-small) + 5px);
}

.benefits-item-text strong {
  font-weight: var(--font-weight-semi-bold);
}

/* ------------- Conócenos ------------- */

.conocenos__container {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 150px 0px;
}

.conocenos__img img {
  height: 100%;
  max-height: 550px;
}

.conocenos__title-mobile {
  display: none;
}

.conocenos__content {
  width: 100%;
  max-width: 690px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.conocenos__content p {
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
}

.conocenos__content strong {
  font-weight: var(--font-weight-semi-bold);
}

.conocenos__title-desktop {
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: 15px;
}

.conocenos__content-text p:nth-of-type(1) {
  margin-bottom: 10px;
}

.conocenos__content-awards {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-content: space-between;
}

.conocenos__content-awards-item {
  display: grid;
  grid-template-rows: 70px 50px;
}

.conocenos__content-awards-title {
  font-size: calc(var(--font-size-tiny) - 1px);
  font-weight: var(--font-weight-semi-bold);
}

.conocenos__btn {
  width: 250px;
  margin-top: 0px !important;
}

/* ------------- Contáctnos ------------- */

.contact {
  position: relative;
  padding-bottom: 120px;
  max-height: 600px;
}

.contact__container {
  background-color: var(--bg-secondary);
  border-radius: 20px;
  padding: 50px 50px 0 50px;
  box-sizing: border-box;
  display: flex;
  gap: 50px;
  justify-content: center;
  box-sizing: border-box;
}

.contact__col-content {
  max-width: 600px;
  box-sizing: border-box;
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 50px;
}

.contact__title {
  font-size: calc(var(--font-size-medium) + 1px);
  font-weight: var(--font-weight-semi-bold);
}

.contact__text {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-regular);
}

.contact__btn {
  background-color: var(--white);
  width: 300px;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-semi-bold);
  color: var(--bg-secondary);
  text-align: center;
  padding: 15px 20px !important;
  border-radius: 50px;
  transition: 0.3s;
}

.contact__btn:hover {
  box-shadow: rgb(69, 35, 90) 0px 20px 30px -10px;
  transition: 0.3s;
}

.contact__col-img {
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.contact__col-img img {
  max-width: 500px;
  height: 95%;
}

.contact__animation {
  position: absolute;
  left: -200px;
  bottom: -100px;
  z-index: var(--z-index-4);
  width: 900px;
}

/* ------------- Frequent Questions ------------- */
.frequentQuestions {
  position: relative;
  padding-bottom: 150px;
}

.frequentQuestions__title {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);
  text-align: center;
}

.transition,
ul li i:before,
ul li i:after,
.contAcordeon {
  transition: all 0.25s ease-in-out;
}

.flipIn,
ul li {
  animation: flipdown 0.5s ease both;
}

#frequentQuestions__container {
  width: 100%;
  max-width: 960px;
  height: auto;
  min-height: 0;
  display: inline-block;
  position: relative;
  left: 50%;
  margin: 50px 0 10px;
  transform: translate(-50%, 0);
  background-color: var(--white);
}

.contAcordeon {
  width: 100%;
  height: auto;
  max-height: 200em;
  color: var(--black);
  font-size: var(--font-size-tiny);
  position: relative;
  overflow: hidden;
  opacity: 1;
  transform: translate(0, 0);
  margin: 20px 0px;
  z-index: 2;
  text-align: justify;
}

#frequentQuestions__container ul li h2 {
  padding: 20px;
  box-sizing: border-box;
}

#frequentQuestions__container ul li i.chevron {
  position: absolute;
  transform: translate(-18px, 0);
  margin: 33px -10px 0 0;
  right: 0;
}

#frequentQuestions__container ul li i.chevron:before,
#frequentQuestions__container ul li i.chevron:after {
  content: "";
  position: absolute;
  top: -2px;
  right: 15px;
  background-color: var(--bg-secondary);
  width: 3px;
  height: 9px;
}

#frequentQuestions__container ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}

#frequentQuestions__container ul li i.chevron:after {
  transform: translate(2px, 0) rotate(-45deg);
}

#frequentQuestions__container ul li input[type=checkbox] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0px;
}

#frequentQuestions__container ul li input[type=checkbox]:checked~div {
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}

#frequentQuestions__container ul li input[type=checkbox]:checked~i:before {
  transform: translate(2px, 0) rotate(45deg);
}

#frequentQuestions__container ul li input[type=checkbox]:checked~i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

#frequentQuestions__container input[type=checkbox]~h2 {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-semi-bold);
  width: 100%;
  background-color: #F9F5FF;
  border-radius: 10px;
}

.frequentQuestions__btn {
  position: absolute;
  bottom: 100px;
  left: calc(50% - 100px);
  width: 200px;
  text-align: center;
  box-sizing: border-box;
  padding: 12px 35px 15px;
}

@keyframes flipdown {
  0% {
      opacity: 0;
      transform-origin: top center;
      transform: rotateX(-90deg);
  }

  5% {
      opacity: 1;
  }

  80% {
      transform: rotateX(8deg);
  }

  83% {
      transform: rotateX(6deg);
  }

  92% {
      transform: rotateX(-3deg);
  }

  100% {
      transform-origin: top center;
      transform: rotateX(0deg);
  }
}

/* ------------- Blog ------------- */

.blog {
  width: 100vw;
  height: auto;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  overflow: hidden;
  background-color: rgba(205, 168, 228, 0.231);
  ;
  padding: 80px 0;
}

.blog__title {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-medium);
  text-align: center;
}

.blog__cards {
  display: flex;
  gap: 20px;
}

.blog__cards-item {
  overflow: hidden;
  width: 386px;
  height: 440px;
  background-color: var(--white);
  border-radius: 20px;
  transform: scale(0.95);
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.blog__cards-item:hover {
  transform: scale(1);
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.blog__cards-item-img {
  overflow: hidden;
  width: 100%;
  height: 268px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog__cards-item-img img {
  width: 386px;
  height: 268px;
  transition: .3s;
  transform: scale(1);
}

.blog__cards-item:hover .blog__cards-item-img img {
  transition: .3s;
  transform: scale(1.1);
}

.blog__cards-item-content {
  background-color: var(--white);
  width: 100%;
  height: calc(440px - 268px);
  padding: 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 25px;
  gap: 5px;
}

.blog__cards-item-title {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-semi-bold);
}

.blog__cards-item-btn {
  text-align: left;
  color: var(--bg-secondary);
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: var(--font-size-tiny);
}

.blog__cards-item:hover .blog__cards-item-btn {
  color: var(--bg-primary);
  transition: 0.3s;
}

.blog__cards-item-btn svg path {
  stroke: var(--bg-secondary);
  transition: 0.3s;
}

.blog__cards-item:hover .blog__cards-item-btn svg path {
  stroke: var(--bg-primary);
  transition: 0.3s;
}

.blog__controls {
  display: none;
}

.blog__controls_btn {
  background-color: var(--bg-primary);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: scale(0.8);
  transition: 0.3s;
}

.blog__controls_btn:hover,
.blog__controls_btn.clicked {
  background-color: var(--bg-secondary);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  transform: scale(1);
  transition: 0.3s;
}

.blog__cards-mobile {
  display: none;
}

.swiper-container-blog,
.swiper-slide-blog {
  max-width: 386px;
  width: calc(100vw - 70px);
  max-height: 440px;
  height: calc(50vh);
  border-radius: 20px;
}

swiper-slide {
  border-radius: 18px;
}

/* ------------- Footer ------------- */
footer {
  background-color: var(--bg-secondary);
  width: 100vw;
  height: 116px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px calc(12.5vw + 40px);
  box-sizing: border-box;
  color: var(--white);
}

footer .footer__logo {
  transform: scale(.8);
}

footer .footer__socialNetwork {
  display: flex;
  gap: 25px;
}

footer .footer__socialNetwork_item {
  display: flex;
  align-items: center;
  gap: 5px;
}

footer .footer__socialNetwork_item_icon {
  width: 25px;
  height: 25px;
  padding: 3px;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 50%;
}   

footer .footer__socialNetwork_item_icon svg {
  fill: var(--bg-secondary);
}

footer .footer__socialNetwork_item span {
  color: var(--white);
  font-size: 14px;
}