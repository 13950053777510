/* 1270 revisar */
@media (max-width: 960px) {

    .sectionFake {
        bottom: 20vh;
    }

    #sectionVideo {
        top: -15vh;
    }

    #sectionBenefits {
        top: -15vh;
    }

    #sectionConocenos {
        top: -60px;
    }

    #sectionContact {
        top: -80px;
    }

    #sectionBlog {
        top: -60px;
    }

    /* ------------- Header ------------- */
    header {
        width: 100vw;
        left: 0;
        height: 70px;
        border-radius: 0;
        padding: 0 28px 0 0;
    }

    header.scrolled {
        height: 70px;
    }

    header button.btnLogo svg {
        height: 35px;
        margin-left: -10px;
    }

    nav {
        position: absolute;
        background-color: var(--white);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: var(--z-index-1);
        display: grid;
        grid-template-rows: 20px 3fr 1fr;
        padding: 40px;
        box-sizing: border-box;
        transform: translateX(100vw);
        transition: 0.6s all 0.3s;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }

    nav ul {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 25px;
        width: 100%;
        box-sizing: border-box;
    }

    nav ul li {
        margin: 0;
        text-transform: uppercase;
        text-align: right;
    }
    
    nav ul li button a {
        color: var(--bg-secondary);
        padding: 0;
        font-size: var(--font-size-small);
    }

    .li_icon {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .li_icon svg {
        width: 24px;
    }

    .line {
        display: none;
    }

    button.btnMenuMobileUp {
        display: flex;
    }

    button.btnMenuMobileDown {
        display: flex;
        margin-left: auto;
    }

    .containerMobileNav {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--bg-secondary);
        width: 100%;
        justify-content: flex-end;
        padding: 5px 0 60px 0;
        box-sizing: border-box;
    }

    .loboMobileNav {
        transform: scale(0.6);
        transform-origin: left;
        margin-left: -7px;
    }

    .lenguageBtn {
        color: var(--bg-secondary);
        width: 100px;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
        font-size: var(--font-size-small);
    }

    .socialNetworksMobile {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 5px;
    }

    .socialNetworksMobile_item {
        display: flex;
        align-items: center;
        margin-left: -5px;
        gap: 10px;
    }
    
    .socialNetworksMobile_item span {
        font-size: 16px;
        color: var(--bg-secondary);
        font-weight: var(--font-weight-semi-bold);
    }
    
    .socialNetworksMobile .nav_icon_mobile {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        background-color: var(--bg-secondary);
        padding: 2px;
    }

    .nav_icon_mobile svg {
        fill: var(--white);
    }

    .lenguageNewTextCurrent {
        margin-right: 15px;
    }

    .lenguage .li_icon {
        width: 30px;
    }

    #wspMobile img {
        transform: scale(1.15);
    }

    /* ------------- Sections ------------- */

    /* Home */
    .home {
        justify-content: flex-start;
        height: 90vh;
        padding: 18vw 0 0 35px;
    }

    .home .home__text {
        font-size: calc(var(--font-size-medium) + 3px);
        width: 100%;
        max-width: 350px;
    }

    .home__subimages {
        gap: 20px;
        margin-top: 0px;
    }

    .home__subimages span {
        font-size: var(--font-size-medium);
    }

    .home__subimages-image {
        height: 25px;
    }

    .home__mainImage-img {
        right: -60px;
        bottom: 20px;
        max-height: 360px;
     }

    #home__svg {
        right: -160px;
        bottom: 30px;
        max-height: 360px;
        margin-bottom: 0px;
    }

    .btnWsp {
        bottom: 35px;
        right: 20px;
        width: 60px;
        height: 60px;
    }

    /* Brands */
    .brands {
        margin-top: 100px;
    }

    .brands-mobile {
        display: flex;
    }
    .brands-desktop {
        display: none;
    }

    .partners {
        margin: 10px 0 100px;
    }

    /* ------------- Globals ------------- */
    section {
        padding: 0px 35px;
    }
    
    /* ------------- Media Player ------------- */
    .mediaplayer {
        gap: 20px;
        height: 65vh;
    }

    .mediaplayer__btn {
        margin-top: 20px;
        transform: scale(0.8);
    }

    .mediaplayer__video {
        height: 35vh;
        max-width: 80vw;
        min-height: 120px;
    }

    /* ------------- Beneficios ------------- */

.benefits-items {
    display: none;
}

.benefits-items-mobile {
    display: flex;
    width: 100%;
}

    /* ------------- Conócenos ------------- */

    .conocenos__container {
        flex-direction: column;
        align-items: center;
        gap: 40px;
        padding: 50px 0px 100px 0px;
    }

    .conocenos__img img {
        width: 100%;
        max-width: 400px;
    }

    .conocenos__content {
        gap: 25px;
    }

    .conocenos__content p {
        font-size: calc(var(--font-size-tiny) - 2px);
        text-align: center;
    }

    .conocenos__content-awards {
        margin-bottom: 20px;
    }

    .conocenos__content-awards-icon {
        display: flex;
        justify-content: center;
        transform: scale(0.7);
    }

    .conocenos__title-mobile {
        display: flex;
        justify-content: center;
        font-size: var(--font-size-medium);
        line-height: var(--font-size-medium);
        font-weight: var(--font-weight-semi-bold);
    }

    .conocenos__title-desktop {
        display: none;
    }

    .conocenos__content-awards-title {
        font-size: calc(var(--font-size-tiny) - 4px);
        text-align: center;
        line-height: calc(var(--font-size-tiny));
    }

    .conocenos__btn {
        margin: 0 auto;
    }

    /* ------------- Frequent Questions ------------- */
    .frequentQuestions {
        padding-bottom: 150px;
    }

    #frequentQuestions__container input[type=checkbox]~h2 {
        font-size: var(--font-size-tiny);
    }

    .contAcordeon {
        font-size: calc(var(--font-size-tiny) - 2px);
    }

    .frequentQuestions__btn {
        transform: scale(0.8);
    }

    #frequentQuestions__container ul li h2 {
        padding: 20px 45px 20px 20px;
    }

    /* ------------- Contact ------------- */

    .contact {
        max-height: 1000px;
        overflow: hidden;
    }

    .contact__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 50px;
        padding: 0 20px;
        text-align: center;
    }

    .contact__text {
        font-size: var(--font-size-tiny);
        font-weight: var(--font-weight-light);
    }

    .contact__col-content {
        padding: 40px 0 0;
        gap: 20px;
        max-width: 1000px;
    }

    .contact__btn {
        margin: 30px auto 20px;
        width: 80%;
        font-size: calc(var(--font-size-tiny) - 2px);
        padding: 15px 0;
    }

    .contact__col-img {
        max-width: 1000px;
    }

    .contact__col-img img {
        max-width: 200px;
        width: 100%;
        max-height: 300px;
        height: auto;
    }

    .contact__animation {
        left: -600px;
        width: 1000px;
        bottom: 100px;
    }

    /* ------------- Blog ------------- */

    .blog {
        min-height: 550px;
    }

    .blog__cards {
        display: none;
    }

    .blog__cards-mobile {
        display: flex;
    }

    .blog__cards-item {
        max-width: 386px;
        width: calc(100vw - 70px);
        max-height: 440px;
        height: calc(50vh);
        transform: scale(1);
        background: var(--white);
        box-shadow: none;
    }

    .blog__cards-item:hover {
        transition: 0s;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
    }

    .blog__cards-item-img {
        width: 100%;
        height: auto;
    }

    .blog__cards-item-img img {
        width: 100%;
        height: auto;
    }

    .blog__cards-item:hover .blog__cards-item-img img {
        transition: 0s;
        transform: scale(1);
    }

    .blog__cards-item-content {
        width: 100%;
        height: calc(440px - 268px - 20px);
        padding: 20px 30px;
        background-color: transparent;
    }

    .blog__cards-item-title {
        font-size: var(--font-size-small);
    }

    .blog__cards-item-btn {
        font-size: calc(var(--font-size-tiny) - 2px);
    }

    .blog__cards-item:hover .blog__cards-item-btn {
        color: var(--bg-secondary);
        transition: 0s;
    }

    .blog__cards-item-btn svg path {
        stroke: var(--bg-secondary);
        transition: 0.3s;
    }

    .blog__cards-item:hover .blog__cards-item-btn svg path {
        stroke: var(--bg-secondary);
        transition: 0s;
    }

    /* ------------- Footer ------------- */

    footer {
        height: 216px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 30px 35px;
        box-sizing: border-box;
    }

    footer .footer__logo {
        transform: scale(.7);
        margin-left: -30px;
    }

    footer .footer__socialNetwork {
        gap: 5px;
        flex-direction: column;
    }
    
    footer .footer__socialNetwork_item {
        padding: 0px;
        margin-left: -3px;
    }
    
    footer .footer__socialNetwork_item_icon {
        width: 25px;
        height: 25px;
        padding: 3px;
        box-sizing: border-box;
        background-color: var(--white);
        border-radius: 50%;
    }   
    
    footer .footer__socialNetwork_item_icon svg {
        fill: var(--bg-secondary);
    }
    
    footer .footer__socialNetwork_item span {
        color: var(--white);
        font-size: 14px;
    }
}

@media (min-width: 960px) and (max-width: 1470px) {

    #sectionContact {
        top: -20vh;
    }

    #sectionBlog {
        top: -60px;
    }

    /* ------------- Header ------------- */
    header {
        left: 0;
        width: 100vw;
        padding: 0px 30px;
        height: 80px;
    }

    header.scrolled {
        height: 80px;
    }

    header .btnLogo {
        transform-origin: left;
        transform: scale(0.8);
    }

    /* ------------- Globals ------------- */
    section {
        padding: 0px 35px;
    }

    a {
        font-size: calc(var(--font-size-tiny) - 2px);
    }
    .home {
        height: 100vh;
    }

    .home__mainImage-img {
        max-height: 500px;
        right: -40px;
    }

    .home__subimages {
        gap: 30px;
        margin-top: 0px;
    }

    #home__svg {
        height: 500px !important;
        right: -40px;
        margin-bottom: 30px;
    }

    .btnWsp {
        bottom: 120px;
        right: 20px;
        width: 60px;
        height: 60px;
        padding-bottom: 2px;
    }

    /* MediaPlayer */
    .mediaplayer {
        width: 100vw;
        height: 100vh;
        max-height: 600px;
        gap: 20px;
        margin-top: 100px;
    }

    .mediaplayer__video {
        height: 50vh;
        min-height: 370px;
    }

    .mediaplayer__btn {
        margin-top: 20px;
    }

    /* ------------- Contact ------------- */

    .contact__col-content {
        width: 50%;
        padding: 0 0 40px 0;
        gap: 30px;
        justify-content: center;
    }

    .contact__container {
        gap: 15%;
    }

    .contact__title {
        font-size: var(--font-size-regular);
    }

    .contact__text {
        font-size: var(--font-size-small);
    }

    .contact__col-img img {
        width: 100%;
        height: auto;
    }

    .contact__btn {
        margin: 0px;
    }

    .contact__animation {
        left: -400px;
    }

    /* ------------- Conócenos ------------- */

    .conocenos__container {
        gap: 40px;
    }

    .conocenos__img img {
        max-width: 400px;
    }

    .conocenos__content {
        width: 100%;
        max-width: 690px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
    }

    .conocenos__content p {
        font-size: calc(var(--font-size-tiny) - 2px);
    }

    .conocenos__content-awards-icon {
        display: flex;
        transform: scale(0.7);
    }

    .conocenos__content-awards-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
    
    .conocenos__content-awards-title {
        font-size: calc(var(--font-size-tiny) - 4px);
        line-height: calc(var(--font-size-tiny));
        text-align: left;
    }

    .conocenos__btn {
        width: 250px;
        margin-top: 0px !important;
    }

    /* ------------- Blog ------------- */

    .blog {
        gap: 0px;
    }

    .blog__cards {
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 20px;
        transform: scale(0.8);
    }

    .blog__cards-item {
        overflow: hidden;
        width: 30%;
        height: auto;
    }

    .blog__cards-item-img {
        width: 100%;
        height: auto;
    }

    .blog__cards-item-img img {
        width: 100%;
        height: auto;
    }

    .blog__cards-item-content {
        height: calc(440px - 268px - 10px);
    }

    .blog__cards-item-title {
        font-size: var(--font-size-small);
    }

    .blog__cards-mobile {
        display: none;
    }

    /* ------------- Footer ------------- */

    footer {
        padding: 0px 30px;
    }

}